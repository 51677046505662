<template>
  <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
  >
    <v-card>

      <v-card-title class="text-h6 justify-center"> {{ title }}</v-card-title>
      <v-card-text
          v-show="!!message"
          class="px-4 text-body-1 black--text text-center"
          v-html="message"
      ></v-card-text>
      <v-card-actions class="pt-3 justify-center">
        <v-btn
            large
            color="success"
            @click.native="agree"
        >Ano</v-btn>
        <v-btn
            large
            v-if="!options.noConfirm"
            color="grey"
            outlined
            @click.native="cancel"
        >Zpět</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    type: {
      type: String,
      default: 'confirm'
    }
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: "dark",
        width: 400,
        zIndex: null,
        noConfirm: false,
      },
    };
  },
computed: {
  buttons() {
    return {
      confirm: {
        'color': this.type === 'confirm' ? 'success' : 'error'
      },
      cancel: {
        'color': this.type === 'confirm' ? 'success' : 'grey'
      }
    }
  }
},
  methods: {
    open(title = 'null', message = 'null', options = {}) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>